export const supportedLanguages = {
    en: {
        label: "ENG",
        flag: "us"
    },
    us: {
        label: "ENG",
        flag: "us"
    },
    es: {
        label: "ESP",
        flag: "es"
    }
}

export const companyDetails = {
    email: "info@visitallnepal.com",
    code: "+977",
    contact: "9818192604",
    directChat: "https://tawk.to/chat/61307d44649e0a0a5cd42fc5/1feimiloa"
}